import environment from "@/constants/Environment";
const environmentData = environment();
let fbSDKalreadyLoaded = false;
let isRunning = false;

const scope: any = {
  user_profile: ["public_profile", "email", "business_management"],
  facebook_pages: [
    "pages_show_list",
    "pages_manage_metadata",
    "pages_messaging",
  ],
  whatsapp_business: [
    "business_management",
    "whatsapp_business_management",
    "whatsapp_business_messaging",
  ],
 instagram_business: [
    "business_basic",
    "business_manage_messages",
  ],
};

window.fbAsyncInit = function () {
  if (fbSDKalreadyLoaded) return;
  FB.init({
    appId: String(environmentData.PUBLIC_FACEBOOK_APP_ID),
    autoLogAppEvents: true,
    xfbml: true,
    version: environmentData.META_API_VERSION,
  });
  fbSDKalreadyLoaded = true;
};

export function userLogin(codeChallenge: string) {
  const query = new URLSearchParams({
    client_id: String(environmentData.PUBLIC_FACEBOOK_APP_ID),
    redirect_uri: window.location.origin + "/auth/facebook",
    response_type: "code",
    auth_type: "rerequest",
    config_id: String(environmentData.META_CONFIGS?.login),
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
  });

  window.open(
    `https://www.facebook.com/${environmentData.META_API_VERSION}/dialog/oauth?${query}`,
    "_top",
  );
}

export async function fbPagesLogin() {
  const prevUrl = String(window.location.pathname);
  const query = new URLSearchParams({
    client_id: String(environmentData.PUBLIC_FACEBOOK_APP_ID),
    redirect_uri: window.location.origin + "/authorize/facebook",
    response_type: "code",
    auth_type: "rerequest",
    // config_id: String(environmentData.META_CONFIGS?.pages),
    scope: scope.facebook_pages.join(),
    state: `{"prevUrl": "${prevUrl}"}`,
  });

  window.open(
    `https://www.facebook.com/${environmentData.META_API_VERSION}/dialog/oauth?${query}`,
    "_top",
  );
}
export async function instagramLogin() {
  const prevUrl = String(window.location.pathname);
  const query = new URLSearchParams({
    client_id: String(environmentData.PUBLIC_INSTAGRAM_APP_ID),
    redirect_uri: window.location.origin + "/authorize/instagram",
    response_type: "code",
    // auth_type: "rerequest",
    // config_id: String(environmentData.META_CONFIGS?.pages),
    scope: scope.instagram_business.join(),
    state: `{"prevUrl": "${prevUrl}"}`,
  });
  //  https://www.instagram.com/oauth/authorize?client_id=97563852873&redirect_uri=https://my.m.redirect.net/&response_type=code&scope=business_basic%2Cbusiness_manage_messages

  const popup = window.open(`https://www.instagram.com/oauth/authorize?${query}`, "popup", "popup=true");
  //check and accordingly perform action
  // const checkPopup = setInterval(() => {
  //   if (popup?.window.location.href.includes(prevUrl)) {popup.close()}
  //   if (!popup || !popup.closed) return;
  //   clearInterval(checkPopup);
  // }, 1000);
}

export async function whatsappLogin(forced: boolean = false) {
  const prevUrl = String(window.location.pathname);
  const query = new URLSearchParams({
    client_id: String(environmentData.PUBLIC_FACEBOOK_APP_ID),
    redirect_uri: window.location.origin + "/authorize/whatsapp",
    response_type: "code",
    auth_type: "rerequest",
    // config_id: String(environmentData.META_CONFIGS?.whatsapp),
    scope: scope.whatsapp_business.join(),
    state: `{"prevUrl": "${prevUrl}", "forced": ${forced}}`,
  });

  window.open(
    `https://www.facebook.com/${environmentData.META_API_VERSION}/dialog/oauth?${query}`,
    "_top",
  );
}

export async function logout() {
  return new Promise<string>((resolve) => {
    FB.getLoginStatus(({ status }) => {
      isRunning = false;
      if (status !== "connected") return resolve("already logged out");
      FB.logout(() => resolve("logout successfully"));
    });
  });
}

// export async function fetchProfile() {
//   return new Promise<any>((resolve) => {
//     FB.api(
//       "/me",
//       {
//         fields: ["name", "email", "picture"],
//       },
//       (response) => resolve(response),
//     );
//   });
// }

// export async function getLoginStatus() {
//   return new Promise<facebook.StatusResponse>((resolve) => {
//     FB.getLoginStatus((response) => resolve(response));
//   });
// }

// export async function fbPagesLogin() {
//   if (isRunning) {
//     throw new Error("already running", {
//       cause: "suppress",
//     });
//   }
//   isRunning = true;

//   // const { status, authResponse } = await getLoginStatus();
//   // if (status === "connected") return authResponse;

//   const alreadyLoggedIn = await fetchPermissionsAndLoginStatus(
//     scope.facebook_pages
//   );
//   if (alreadyLoggedIn) return alreadyLoggedIn;

//   return new Promise<facebook.AuthResponse>((resolve, reject) => {
//     FB.api("/me", function (response) {
//       console.log(JSON.stringify(response));
//     });

//     FB.login(
//       (response) => {
//         isRunning = false;
//         if (response.status === "connected") {
//           const headers = {
//             Authorization: `Bearer ${String(
//               environmentData?.PUBLIC_FACEBOOK_APP_ID
//             )}`,
//           };
//           resolve(response.authResponse);
//         }
//         reject("Failed to connect with facebook");
//       },
//       {
//         scope: scope.facebook_pages.join(),
//       }
//     );
//   });
// }

// export async function whatsappLogin(setIsWhatsappLoading: any) {
//   if (isRunning) {
//     throw new Error("already running", {
//       cause: "suppress",
//     });
//   }
//   isRunning = true;
//   setIsWhatsappLoading(true);

//   const alreadyLoggedIn = await fetchPermissionsAndLoginStatus(
//     scope.whatsapp_business
//   );
//   if (alreadyLoggedIn) return alreadyLoggedIn;

//   return new Promise<facebook.AuthResponse>((resolve, reject) => {
//     //@ts-ignore
//     // if(fbq) fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: '2106755656362298', feature: 'whatsapp_embedded_signup'});

//     FB.login(
//       (response) => {
//         isRunning = false;
//         setIsWhatsappLoading(false);
//         if (response.status === "connected") {
//           if (response.authResponse) {
//             const code = response.authResponse.code;

//             //  axiosPost("/auth/whatsapp/oauth", { code: code, authProvider: "whatsapp" }).then((res)=> {
//             //   console.log(environmentData?.FACEBOOK_PAGE_ACCESS_TOKEN, "facebook access token")
//             //   const headers = {
//             //     Authorization: `Bearer ${environmentData?.FACEBOOK_PAGE_ACCESS_TOKEN}`,
//             //   }
//             //   console.log(res.data, 'res')
//             //   axios.get(
//             //     `https://graph.facebook.com/${environmentData.META_API_VERSION}/debug_token?input_token=` +
//             //       `${environmentData?.FACEBOOK_PAGE_ACCESS_TOKEN}`,
//             //     {
//             //       headers: headers
//             //     }
//             //     // res.data.data.authData.authToken.access_token
//             //   )
//             //   .then((res) => {
//             //     const granularScopes = res.data.data.granular_scopes || [];

//             //     const isWhatsAppBusinessMessaging = granularScopes.filter(
//             //       (scope) => {
//             //         return scope.scope === "whatsapp_business_messaging";
//             //       }
//             //     );

//             //     console.log(isWhatsAppBusinessMessaging, "isWhatsAppBusinessMessaging")

//             //     if (isWhatsAppBusinessMessaging.length > 0) {
//             //       let phoneNumber = "916283415102";
//             //       const authToken = environmentData?.FACEBOOK_PAGE_ACCESS_TOKEN;
//             //       console.log(authToken, "authToken")
//             //       const whatsappBusinessId =
//             //         isWhatsAppBusinessMessaging[0].target_ids[0];

//             //       axios
//             //         .get(
//             //           `https://graph.facebook.com/${environmentData.META_API_VERSION}/${whatsappBusinessId}/phone_numbers`,
//             //           {
//             //             headers: {
//             //               Authorization: `Bearer ${environmentData?.FACEBOOK_PAGE_ACCESS_TOKEN}`,
//             //             },
//             //           }
//             //         )
//             //         .then((res) => {
//             //           console.log(res.data.data[0].id, "phone number id");
//             //           console.log(res.data.data[0].display_phone_number, "phone numbers ");
//             //           phoneNumber = res.data.data[0].display_phone_number;

//             //           // axiosPost("/users/setAppConfig", {
//             //           //   siteName: "whatsapp",
//             //           //   accessToken: authToken,
//             //           //   userId: "authResponse.userID",
//             //           //   accountName: "profile.name",
//             //           //   picture: profile.picture?.data?.url,
//             //           // });

//             // axiosPost(
//             //   "/configurations/addWhatsappConfig?accountType=Whatsapp",
//             //   {
//             //     configName: "Whatsapp",
//             //     "authToken": "oka",
//             //     "phoneNumber": "oka",
//             //     "phoneNumberId": "res.data.data[0].id",
//             //     "whatsappBusinessId": "oka",
//             //   }
//             // ).then((res) => {
//             //   console.log(res, "response saved");
//             // });
//             //         });
//             //     } else {
//             //       console.log("No WhatsApp Business Messaging scope found.");
//             //     }
//             //   });

//             //  });
//           }
//           resolve(response.authResponse);
//         }
//         reject("Failed to connect whatsapp business account");
//       },
//       {
//         scope: scope.whatsapp_business.join(),
//         // config_id: "636732088586920",
//         // response_type: 'code',
//         // override_default_response_type: true,
//         // extras: {
//         //   featureType: "only_waba_sharing", // Bypass phone number selection
//         // },
//       }
//     );
//   });
// }
